import './Device.scss';

export const Device = ({ children, device, orientation, color }) => {
  return (
    <div className='device-wrapper'>
      <div className='device' data-device={device} data-orientation={orientation} data-color={color}>
        <div className='screen'>{children}</div>
        <div className='button'>
          {/* <!-- You can hook the "home button" to some JavaScript events or just remove it --> */}
        </div>
      </div>
    </div>
  );
};
