import { FormattedMessage } from 'react-intl';
import { HashLink } from 'react-router-hash-link';
import logo from '../assets/images/smoove.png';
import './Footer.scss';

export function Footer() {
  const emojis = ["❤️", "🦄", "🍻", "🌊", "🌞", "🚀"];
  const selectedEmoji = emojis[Math.floor(Math.random() * (emojis.length))];

  return (
    <div className='app-footer'>
      <div className='app-footer__container '>
        <img src={logo} alt='smoove' height='24px' />
        <div className='app-footer__links'>
          <HashLink to='/imprint/#'>
            <FormattedMessage id={'smoove.footer.imprint'} defaultMessage={'Imprint'} />
          </HashLink>
          <HashLink to='/dataprotection/#'>
            <FormattedMessage id={'smoove.footer.data-privacy'} defaultMessage={'Data Protection & Privacy'} />
          </HashLink>
          <a className="mindline-link" href='https://mindline.de/' target="_blank" rel="noopener noreferrer">
            mindline GmbH
          </a>
        </div>
        <div className='app-footer__copyright'>
          <div>© 2024 mindline analytics</div>
          <div>Made with {selectedEmoji} in Hamburg, Germany.</div>
        </div>
      </div>
    </div>
  );
}
