import { useEffect, useState } from 'react';

/**
 * Monitors whether the scroll offset exceeds the defined value
 * and returns the result as a boolean.
 *
 * @param {integer} pageYOffset default 30
 * @returns {boolean} isAbove True, if the scroll y offset is above the defined value
 */
export const useScrollOffset = (pageYOffset = 30) => {
  const [isAbove, setIsAbove] = useState(window.pageYOffset > pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > pageYOffset) setIsAbove(true);
      else setIsAbove(false);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pageYOffset]);

  return isAbove;
};
