import React, { useCallback, useState } from 'react';
import { Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stone } from '../components/Stone';
import { ContactForm } from '../components/ContactForm';

// import shelftool from '../assets/images/shelftool.jpg';
import shelfOne from '../assets/images/shelf/1_shelf_selection.png';
import shelfTwo from '../assets/images/shelf/2_shelf_dnd.png';
import shelfThree from '../assets/images/shelf/shelf-full.png';
// import { FormattedMessage } from 'react-intl';
// import { HashLink } from 'react-router-hash-link';
// import logo from '../assets/images/smoove.png';

import './Usecases.scss';

export const Usecases = () => {
  const translationPrefix = 'smoove.page.usecases';

  const images = [
    {
      img: shelfOne,
      headline: <FormattedMessage id={'smoove.page.usecases.step1-modal-headline'} defaultMessage={`Step 1`} />
    },
    {
      img: shelfTwo,
      headline: <FormattedMessage id={'smoove.page.usecases.step2-modal-headline'} defaultMessage={`Step 2`} />
    }, {
      img: shelfThree,
      headline: <FormattedMessage id={'smoove.page.usecases.step3-modal-headline'} defaultMessage={`Step 3`} />
    }
  ];

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIdx, setSelectedImageIdx] = useState(0);

  const handleToggleModal = useCallback(() => {
    setIsImageModalOpen(b => !b)
  }, [])

  const handleImageSelect = useCallback((imgIdx) => {
    setSelectedImageIdx(imgIdx)
    handleToggleModal()
  }, [handleToggleModal])


  return <div className='page-container usecases-page' id='usecases-page'>
    <div className='app-section' id='usecases-shelf'>
      <Stone
        type={'B'}
        color={'green'}
        width={'250px'}
        position={[-170, null, null, -80, 'rotate(180deg)']}
        translate={[90, 0]}
        className="hide-on-xl"
      />
      <Stone
        type={'C'}
        color={'blue'}
        width={'250px'}
        position={[120, -150, null, null, 'rotate(0)']}
        translate={[90, 0]}
        className="hide-on-xl"
      />
      {/* stones on big screens */}
      <Stone
        type={'B'}
        color={'green'}
        width={'400px'}
        position={[-170, null, null, -200, 'rotate(180deg)']}
        translate={[0, 0]}
        className="show-on-xl"
      />
      <Stone
        type={'C'}
        color={'blue'}
        width={'500px'}
        position={[120, -350, null, null, 'rotate(0)']}
        translate={[0, 0]}
        className="show-on-xl"
      />
      <Container className="mb-4">
        <Row>
          <Col xs={{ span: 12, order: 2 }}>
            <h4>
              <FormattedMessage id={`${translationPrefix}.do-it-your-shelf`} defaultMessage={`Do it your shelf!`} />
            </h4>
            <h1 className='text-smv'>
              <FormattedMessage id={`${translationPrefix}.headline`} defaultMessage={`Usecases`} />
            </h1>
            {/* <div className='row'> */}
            {/* <div className='col-md-12'> */}
           
            <p  dangerouslySetInnerHTML={{ __html: useIntl().formatMessage({ id: `${translationPrefix}.head-text` }) }} />
            {/* <FormattedMessage id={`${translationPrefix}.head-text`} defaultMessage={`Mit dem smoove shelf creator können Projektmanager ganz leicht Regale selbst einrichten und what-you-see-is-what-you-get-mäßig abstimmen. Das spart viel Zeit und schont die Nerven – ganz egal, ob für Designtests oder andere Testverfahren mit Regalteil.`} /> */}
            {/* </div> */}
            {/* </div> */}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col
            xs={{ span: 12 }}
            md={{ span: 5 }}
            className="d-flex align-items-center"
          >
            <Image src={shelfOne} className='img-block img-usecases img-usecases--left' fluid alt={'Step 1: Empty shelf. Select environment'} />
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 2 }}
            className="usecases-bubble-container"
          >
            <div className="usecases-bubble usecases-bubble--line-bottom">
              1
            </div>
            <div className="usecases-line-bottom">
            </div>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 5 }}
            className="usecases-text-container"
          >
            <div className="usecases-text">
              <h3>
                <FormattedMessage id={'smoove.page.usecases.step1-headline'} defaultMessage={`Step 1`} />
              </h3>
              <p>
                <FormattedMessage id={'smoove.page.usecases.step1-text'} defaultMessage={`Step 1`} />
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 5, order: 1 }}
            className="usecases-text-container usecases-text-container--left"
          >
            <div className="usecases-text usecases-text--left">
              <h3>
                <FormattedMessage id={'smoove.page.usecases.step2-headline'} defaultMessage={`Step 2`} />
              </h3>
              <p>
                <FormattedMessage id={'smoove.page.usecases.step2-text'} defaultMessage={`Step 2`} />
              </p>
            </div>
            <Stone
              type={'A'}
              color={'orange'}
              width={'400px'}
              position={[-100, null, null, -400, 'rotate(180deg)']}
              translate={[0, 0]}
            // className="show-on-xl"
            />
          </Col>
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 2, order: 2 }}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="usecases-line-top">
            </div>
            <div className="usecases-bubble">
              2
            </div>
            <div className="usecases-line-bottom">
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 5, order: 3 }}
            className="d-flex align-items-center pointer"
          >
            <Image src={shelfTwo} className='img-block img-usecases img-usecases--right' fluid alt={'Step 2: Select products'} onClick={() => handleImageSelect(1)} />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 12 }}
            md={{ span: 5 }}
            className="d-flex align-items-center pointer"
          >
            <Image src={shelfThree} className='img-block img-usecases img-usecases--left' fluid alt={'smoove designtest with integrated shelf setup'} onClick={() => handleImageSelect(2)} />
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 2 }}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="usecases-line-top">
            </div>
            <div className="usecases-bubble">
              3
            </div>
            {/* <div className="usecases-line-bottom">
            </div> */}
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 5 }}
            className="usecases-text-container"
          >
            <div className="usecases-text">
              <h3>
                <FormattedMessage id={'smoove.page.usecases.step3-headline'} defaultMessage={`Step 3`} />
              </h3>
              <p>
                <FormattedMessage id={'smoove.page.usecases.step3-text'} defaultMessage={`Step 3`} />
              </p>
            </div>
            <Stone
              type={'C'}
              color={'red'}
              width={'250px'}
              position={[0, -250, null, null, 'rotate(0)']}
              translate={[0, 0]}
              className="hide-on-xl"
            />
            <Stone
              type={'C'}
              color={'red'}
              width={'300px'}
              position={[0, -450, null, null, 'rotate(0)']}
              translate={[0, 0]}
              className="show-on-xl"
            />
          </Col>
        </Row>
        {/* <Row>
          <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 5, order: 1 }}
            className="usecases-text-container usecases-text-container--left"
          >
            <div className="usecases-text usecases-text--left">
              <h3>
                <FormattedMessage id={'smoove.page.usecases.step4-headline'} defaultMessage={`Step 4`} />
              </h3>
              <p>
                <FormattedMessage id={'smoove.page.usecases.step4-text'} defaultMessage={`Step 4`} />
              </p>
            </div>
            <Stone
              type={'B'}
              color={'green'}
              width={'250px'}
              position={[380, null, null, -200, 'rotate(180deg)']}
              translate={[0, 0]}
              className="hide-on-xl"
            />

            <Stone
              type={'B'}
              color={'green'}
              width={'350px'}
              position={[380, null, null, -300, 'rotate(180deg)']}
              translate={[0, 0]}
              className="show-on-xl"
            />
          </Col>
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 2, order: 2 }}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="usecases-line-top">
            </div>
            <div className="usecases-bubble">
              4
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 5, order: 3 }}
            className="d-flex align-items-center"
          >
            <Image src={shelfTwo} className='img-block img-usecases img-usecases--right' fluid alt={'Step 3: Full shelf. Move products via drag and drop.'} />
          </Col>
        </Row> */}
      </Container>
      <div className='app-section app-section--big' id='demo'>
        <Container>
          <Row>
            <Col md={{ span: 12 }} className='d-flex flex-column align-items-center justify-content-center'>
              <h2>
                <FormattedMessage id={'smoove.start.block-6.h2'} defaultMessage={`Get a Demo`} />
              </h2>
              <a
                href={`mailto:info@mindline-analytics.de?subject=${'Smoove Demo Termin vereinbaren'}&body=${'Liebes Smoove Team, bitte kontaktiert mich zur Vereinbarung eines Demo-Termins. Vielen Dank!'}`}
                variant='primary'
                type='submit'
                className='btn btn-primary mt-2'
              >
                <FormattedMessage id={'smoove.start.block-6.cta-demo'} defaultMessage={`Get a Demo now`} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='app-section' id='contact'>
        <Stone
          type={'C'}
          color={'blue'}
          width={'350px'}
          position={[120, -100, null, null, 'rotate(0)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'C'}
          color={'blue'}
          width={'500px'}
          position={[0, -100, null, null, 'rotate(0)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Stone
          type={'B'}
          color={'green'}
          width={'250px'}
          position={[-170, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'B'}
          color={'green'}
          width={'400px'}
          position={[-170, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
              <h2>
                <FormattedMessage id={'smoove.page.contact.headline'} defaultMessage={`Contact`} />
              </h2>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    <Modal show={isImageModalOpen} onHide={handleToggleModal} size="lg">
      <Modal.Header closeButton>
        {images[selectedImageIdx].headline}
      </Modal.Header>
      <Modal.Body className="text-center">
        <Image src={images[selectedImageIdx].img} className='img-block' fluid alt={'smoove designtest with integrated shelf setup'} />
      </Modal.Body>
    </Modal>
  </div>
};
