import { FormattedMessage, useIntl } from 'react-intl';
import headerimage from '../assets/images/headerimage.jpg';
import './Jumbotron.scss';

export const Jumbotron = () => {
  const intl = useIntl();
  return <div className="smv-jumbotron">
    <img src={headerimage} alt={intl.formatMessage({
      id: 'smoove.start.jumbotron.image-alt',
      defaultMessage: 'superfast & smoove to your insights.'
    })} />
    <div className="jumbotron-text">
      <div className="jumbotron-h1">
        <FormattedMessage id={`smoove.start.jumbotron.h1`} defaultMessage={`schnell & smoove`} />
      </div>
      <div className="jumbotron-h2">
        <FormattedMessage id={`smoove.start.jumbotron.h2`} defaultMessage={`zu Ihren Insights.`} />
      </div>
    </div>
  </div>
}