import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Switch, useLocation } from 'react-router-dom';
import { translations } from './translation/locales';

import './App.scss';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Start } from './pages/Start';
import { Imprint } from './pages/Imprint';
import { GeneralDataProtectionRegulation } from './pages/GeneralDataProtectionRegulation';
import { Contact } from './pages/Contact';
import { Usecases } from './pages/Usecases';
import { Jumbotron } from './components/Jumbotron';

function App() {
  const [locale, setLocale] = useState('de');

  const getTranslations = () => {
    return Object.keys(translations).find(el => {
      return el === locale;
    });
  };
  let location = useLocation();

  return (
    <IntlProvider messages={translations[getTranslations()]} locale={locale} defaultLocale='de'>
      <Header locale={locale} setLocale={setLocale} />
      <Jumbotron locale={locale} />
      <Switch location={location}>
        <Route exact path='/usecases'>
          <Usecases />
        </Route>
        <Route exact path='/imprint'>
          <Imprint />
        </Route>
        <Route exact path='/dataprotection'>
          <GeneralDataProtectionRegulation />
        </Route>
        <Route exact path='/contact'>
          <Contact />
        </Route>
        <Route exact path='/'>
          <Start />
        </Route>
      </Switch>
      <Footer />
    </IntlProvider>
  );
}

export default App;
