import { useState } from 'react';
import logo from '../assets/images/smoove.png';
import { FormattedMessage } from 'react-intl';
import { Image, Nav, Navbar } from 'react-bootstrap';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import { useScrollOffset } from './helpers/useScrollOffset';

import mindlineKugel from '../assets/images/mindline-kugel.png';
import './Header.scss';

export const Header = ({ locale, setLocale }) => {
  const [expanded, setExpanded] = useState(false);

  const shouldShrink = useScrollOffset(30);

  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -60;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const scrollToTop = () => {
    setTimeout(
      () => window.scrollTo({ top: 0, behavior: 'smooth' }), 
      20
    );
  }

  return (
    <div className={`app-header ${shouldShrink ? 'app-header--shrinked' : ''}`}>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <Navbar
        collapseOnSelect
        variant='light'
        bg='light'
        className='app-header__navbar'
        expand='lg'
        expanded={expanded}
      >
        <Navbar.Brand>
          <HashLink scroll={scrollWithOffset} to='/#' className='nav-link smoove-logo' onClick={() => setExpanded(false)}>
            <img src={logo} alt='smoove' height='32px' />
          </HashLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <NavHashLink
              to='/#overview'
              className='nav-link'
              scroll={scrollWithOffset}
              onClick={() => setExpanded(false)}
            >
              <FormattedMessage id={'smoove.header.overview'} defaultMessage={'Overview'} />
            </NavHashLink>
            <NavHashLink to='/#tour' className='nav-link' scroll={scrollWithOffset} onClick={() => setExpanded(false)}>
              <FormattedMessage id={'smoove.header.tour'} defaultMessage={'Tour'} />
            </NavHashLink>
            <NavHashLink to='/#demo' className='nav-link' scroll={scrollWithOffset} onClick={() => setExpanded(false)}>
              <FormattedMessage id={'smoove.header.demo'} defaultMessage={'Demo'} />
            </NavHashLink>
            <NavHashLink to="/usecases" className='nav-link' onClick={() => { setExpanded(false); scrollToTop() }}>
              Usecases
            </NavHashLink>
            <NavHashLink
              to='/contact'
              className='nav-link'
              // scroll={scrollWithOffset}
              onClick={() => { setExpanded(false); scrollToTop() }}
            >
              <FormattedMessage id={'smoove.header.contact'} defaultMessage={'Overview'} />
            </NavHashLink>
            <Nav.Link href='http://app.smoove.zone' className="login-link" target="_blank" rel="noopener noreferrer">
              {/* <Button variant='primary'> */}
              <FormattedMessage id={'smoove.header.login'} defaultMessage={'Login'} />
              {/* </Button> */}
            </Nav.Link>
          </Nav>

          <div className='app-header__locales mx-4'>
            <Nav.Link>
              <span
                className={`locale ${locale === 'de' ? 'locale--active' : ''}`}
                onClick={() => setLocale('de')}
              >DE</span>
              {/* <span
                className={`flag-icon flag-icon-de ${locale === 'de' ? 'flag-icon--active' : ''}`}
                onClick={() => setLocale('de')}
              ></span> */}
            </Nav.Link>
            <span>|</span>
            <Nav.Link>
              <span
                className={`locale ${locale === 'en' ? 'locale--active' : ''}`}
                onClick={() => setLocale('en')}
              >EN</span>
              {/* <span className={`flag-icon flag-icon-gb ${locale === 'en' ? 'flag-icon--active' : ''}`}
                onClick={() => setLocale('en')}
              ></span> */}
            </Nav.Link>
          </div>
          <Nav className='mindline-link-container'>
            <hr />
            <Nav.Link className="mindline-link" href='http://mindline.de' target="_blank" rel="noopener noreferrer">
              <div className="mindline-text">
                <FormattedMessage id={'smoove.misc.more-about'} defaultMessage={'more about'} /><br />
                mindline
              </div>
              <Image src={mindlineKugel} className='img-mindline' fluid alt={'mehr über mindline'} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
