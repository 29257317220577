import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';
import { Col, Container, Row, Image, Modal } from 'react-bootstrap';
import { Stone } from '../components/Stone';
import start from '../assets/images/start.gif';
import dashboard from '../assets/images/dashboard.png';
import dashboardLg from '../assets/images/dashboard-lg.png';
import tools from '../assets/images/tools.svg';
import tour2 from '../assets/images/tour.svg';
import tour_mobile from '../assets/images/tour_mobile.svg';
import shelftool from '../assets/images/shelftool.jpg';
import './Start.scss';
import { Device } from '../components/Device';
import { ContactForm } from '../components/ContactForm';

export const Start = () => {

  const images = useMemo(() => {
    return [
    {
      img: start,
      headline: <FormattedMessage id={'smoove.page.usecases.step1-modal-headline'} defaultMessage={`Step 1`} />
    },
    {
      img: shelftool,
      headline: <FormattedMessage id={'smoove.page.usecases.step3-modal-headline'} defaultMessage={`Step 3`} />
    }, {
      img: dashboardLg,
      headline: <FormattedMessage id={'smoove.page.usecases.step2-modal-headline'} defaultMessage={`Step 2`} />
    }
  ]}, []);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIdx, setSelectedImageIdx] = useState(0);

  const handleToggleModal = useCallback(() => {
    setIsImageModalOpen(b => !b)
  }, [])

  const handleImageSelect = useCallback((imgIdx) => {
    if (images[imgIdx]) {
      setSelectedImageIdx(imgIdx)
      handleToggleModal()
    }
  }, [handleToggleModal, images])

  return (
    <div className='page-container start-page'>
      <div className='app-section'>
        {/* stones on medium screens */}
        <Stone
          type={'B'}
          color={'green'}
          width={'250px'}
          position={[-170, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'C'}
          color={'blue'}
          width={'350px'}
          position={[120, -100, null, null, 'rotate(0)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        {/* stones on big screens */}
        <Stone
          type={'B'}
          color={'green'}
          width={'400px'}
          position={[-170, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Stone
          type={'C'}
          color={'blue'}
          width={'500px'}
          position={[120, -100, null, null, 'rotate(0)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} >
              <h1>
                <FormattedMessage
                  id={'smoove.start.block-1.sub-headline'}
                  defaultMessage={'Marketing research made easy'}
                />
              </h1>
              <p>
                <FormattedMessage
                  id={'smoove.start.block-1.text'}
                  defaultMessage={
                    'smoove offers accompanied DIY market research at the crossroads of standardisation and agility.'
                  }
                />
              </p>
              <ul>
                <li>
                  <FormattedMessage
                    id={'smoove.start.block-1.li-1'}
                    defaultMessage={'Fully diy or accompanied by us'}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id={'smoove.start.block-1.li-2'}
                    defaultMessage={'fully standardised or partly customised'}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id={'smoove.start.block-1.li-3'}
                    defaultMessage={'pure results delivery or interpretation by one of our experienced consultants'}
                  />
                </li>
              </ul>
              <p>
                <FormattedMessage
                  id={'smoove.start.block-1.list-subline'}
                  defaultMessage={'In any case – you have full control over the research process.'}
                />
              </p>
              <a
                href={`mailto:info@mindline-analytics.de?subject=${'Smoove Demo Termin vereinbaren'}&body=${'Liebes Smoove Team, bitte kontaktiert mich zur Vereinbarung eines Demo-Termins. Vielen Dank!'}`}
                variant='primary'
                type='submit'
                className='btn btn-primary'
              >
                <FormattedMessage id={'smoove.start.block-1.cta'} defaultMessage={'Try now!'} />
              </a>
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} className='d-flex justify-content-center align-items-center mb-4'>
              <Device device='iMac' orientation='portrait' color='black'>
                <Image src={start} className='img-block' fluid alt={'smoove - marketing research made easy'} />
              </Device>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='app-section' id='overview'>
        <Stone
          type={'A'}
          color={'orange'}
          width={'350px'}
          position={[120, null, null, -120, 'rotate(180deg)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'A'}
          color={'orange'}
          width={'500px'}
          position={[120, null, null, -120, 'rotate(180deg)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 2 }} className='tour-container'>
              <Image src={tour2} className='img-block d-none d-md-block tour-image' fluid alt={'The smoove process'} />
              <Row>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }} style={{ position: 'relative' }}></Col>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 7, order: 2 }} style={{ position: 'relative' }}>
                  <h2>
                    <FormattedMessage id={'smoove.start.block-2.h2'} defaultMessage={'smoovin’ – a brief overview'} />
                  </h2>
                  <p>
                    <FormattedMessage
                      id={'smoove.start.block-2.text'}
                      defaultMessage={
                        'The standardised smoove approach eliminates many of the complexities of a fully individualised research process'
                      }
                    />
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} className='d-flex justify-content-start mt-2'>
              <LazyLoad height={200}>
                <Image src={tour_mobile} className='img-block d-md-none d-lg-none d-xl-none' fluid alt={'The smoove process'} />
              </LazyLoad>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='app-section' id='tour'>
        <Stone
          type={'D'}
          color={'red'}
          width={'450px'}
          position={[0, -240, null, null, 'rotate(0deg)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'D'}
          color={'red'}
          width={'550px'}
          position={[0, -240, null, null, 'rotate(0deg)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
              <h2>
                <FormattedMessage id={'smoove.start.block-3.h2'} defaultMessage={`smoovable tools`} />
              </h2>
              <p>
                <FormattedMessage
                  id={'smoove.start.block-3.text'}
                  defaultMessage={'Five very common research objectives can already be approached with smoove.'}
                />
              </p>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }} className='d-flex justify-content-start mt-2'>
              <LazyLoad height={200}>
                <Image src={tools} className='img-block' style={{ width: '800px' }} fluid alt={'The smoove toolbox'} />
              </LazyLoad>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='app-section' id=''>
        <Stone
          type={'B'}
          color={'green'}
          width={'250px'}
          position={[120, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'B'}
          color={'green'}
          width={'400px'}
          position={[120, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Container>
          <Row>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 1 }}
              className='d-flex justify-content-center align-items-center'
            >
              <Image src={shelftool} className='img-block' fluid alt={'smoove designtest with integrated shelf setup'} />
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              <h2>
                <FormattedMessage
                  id={'smoove.start.block-4.headline'}
                  defaultMessage={`DIY Designtest with virtual shelf setup`}
                />
              </h2>
              <p>
                <FormattedMessage id={'smoove.start.block-4.text'} defaultMessage={`Virtual Shelf`} />
              </p>
              <ul>
                <li>
                  <b>
                    <FormattedMessage id={'smoove.start.block-4.li-1.bold'} defaultMessage={`Easy Setup`} />
                  </b>
                  <FormattedMessage id={'smoove.start.block-4.li-1.text'} />
                </li>
                <li>
                  <b>
                    <FormattedMessage id={'smoove.start.block-4.li-2.bold'} defaultMessage={`Editable Design`} />
                  </b>
                  <FormattedMessage id={'smoove.start.block-4.li-2.text'} />
                </li>
                <li>
                  <b>
                    <FormattedMessage id={'smoove.start.block-4.li-3.bold'} defaultMessage={`Result Output`} />
                  </b>
                  <FormattedMessage id={'smoove.start.block-4.li-3.text'} />
                </li>
              </ul>
              <p>
                <FormattedMessage id={'smoove.start.block-4.list-subline'} />
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='app-section' id=''>
        <Stone
          type={'C'}
          color={'blue'}
          width={'350px'}
          position={[120, -100, null, null, 'rotate(0)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'C'}
          color={'blue'}
          width={'500px'}
          position={[120, -100, null, null, 'rotate(0)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
              <h2>
                <FormattedMessage id={'smoove.start.block-5.h2'} />
              </h2>
              <ul>
                <li>
                  <FormattedMessage id={'smoove.start.block-5.list.li-1'} />
                </li>
                <li>
                  <FormattedMessage id={'smoove.start.block-5.list.li-2'} />
                </li>
                <li>
                  <FormattedMessage id={'smoove.start.block-5.list.li-3'} />
                </li>
                <li>
                  <FormattedMessage id={'smoove.start.block-5.list.li-4'} />
                </li>
                <li>
                  <FormattedMessage id={'smoove.start.block-5.list.li-5'} />
                </li>
              </ul>
            </Col>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 2 }}
              className='d-flex justify-content-center align-items-center pointer'
              onClick={() => handleImageSelect(2)}
            >
              <Device device='iMac' orientation='portrait' color='black'>
                <LazyLoad height={200}>
                  <Image src={dashboard} className='img-block' fluid alt={'smoove dashboard'} />
                </LazyLoad>
              </Device>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='app-section app-section--big' id='demo'>
        <Container>
          <Row>
            <Col md={{ span: 12 }} className='d-flex flex-column align-items-center justify-content-center'>
              <h2>
                <FormattedMessage id={'smoove.start.block-6.h2'} defaultMessage={`Get a Demo`} />
              </h2>
              <a
                href={`mailto:info@mindline-analytics.de?subject=${'Smoove Demo Termin vereinbaren'}&body=${'Liebes Smoove Team, bitte kontaktiert mich zur Vereinbarung eines Demo-Termins. Vielen Dank!'}`}
                variant='primary'
                type='submit'
                className='btn btn-primary mt-2'
              >
                <FormattedMessage id={'smoove.start.block-6.cta-demo'} defaultMessage={`Get a Demo now`} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='app-section' id='contact'>
        <Stone
          type={'B'}
          color={'green'}
          width={'250px'}
          position={[120, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'B'}
          color={'green'}
          width={'400px'}
          position={[120, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
              <h2>
                <FormattedMessage id={'smoove.page.contact.headline'} defaultMessage={`Contact`} />
              </h2>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={isImageModalOpen} onHide={handleToggleModal} size="xl">
        <Modal.Header closeButton>
          {images[selectedImageIdx].headline}
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={images[selectedImageIdx].img} className='img-block' fluid alt={'smoove designtest with integrated shelf setup'} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
