import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export const ContactForm = () => {
  const intl = useIntl();

  const [messageBody, setMessageBody] = useState('');

  const handleSetMessageBody = e => {
    setMessageBody(e.target.value);
  };

  return (
    <>
      <Form.Group controlId='exampleForm.ControlTextarea1'>
        <Form.Control
          as='textarea'
          name='message'
          rows={3}
          placeholder={intl.formatMessage({
            id: 'smoove.contact-form.message-placeholder',
            defaultMessage: 'Enter your Message'
          })}
          value={messageBody}
          onChange={e => handleSetMessageBody(e)}
          required
          style={{ minHeight: '200px' }}
        />
      </Form.Group>
      <a
        href={`mailto:info@mindline-analytics.de?subject=${'Smoove'}&body=${encodeURIComponent(messageBody)}`}
        variant='primary'
        type='submit'
        className='btn btn-primary'
      >
        <FormattedMessage id={'smoove.contact-form.submit'} defaultMessage={`Submit`} />
      </a>
    </>
  );
};
