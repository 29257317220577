import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ContactForm } from '../components/ContactForm';
import { Stone } from '../components/Stone';

const translationPrefix = 'smoove.page.contact';

const Contact = props => {
  return (
    <div className='page-container' id='contact-page'>
      <div className="app-section">
        {/* stones on medium screens */}
        <Stone
          type={'B'}
          color={'green'}
          width={'250px'}
          position={[-170, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'C'}
          color={'blue'}
          width={'350px'}
          position={[120, -100, null, null, 'rotate(0)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        {/* stones on big screens */}
        <Stone
          type={'B'}
          color={'green'}
          width={'400px'}
          position={[-170, null, null, -80, 'rotate(180deg)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Stone
          type={'C'}
          color={'blue'}
          width={'500px'}
          position={[120, -100, null, null, 'rotate(0)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, offset: 3 }} >
              <h1 className='text-uppercase text-smv'>
                <FormattedMessage id={`${translationPrefix}.headline`} />
              </h1>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='app-section'>
        <Stone
          type={'A'}
          color={'orange'}
          width={'350px'}
          position={[-200, null, null, -120, 'rotate(180deg)']}
          translate={[90, 0]}
          className="hide-on-xl"
        />
        <Stone
          type={'A'}
          color={'orange'}
          width={'500px'}
          position={[0, null, null, -120, 'rotate(180deg)']}
          translate={[90, 0]}
          className="show-on-xl"
        />
      </div>
    </div>
  );
};

export { Contact };
