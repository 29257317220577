import React from 'react';
import { FormattedMessage } from 'react-intl';

const translationPrefix = 'smoove.page.gtpr';

const GeneralDataProtectionRegulation = props => {
  return (
    <div className='smv-imprint base-content container-fluid page-container'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <h1 className='d-block text-uppercase text-smv font-weight-bold'>
              <FormattedMessage id={`${translationPrefix}.headline`} />
            </h1>
            <h3><FormattedMessage id={`${translationPrefix}.01-responsible`} /></h3>
            <p>
              <b>
                mindline analytics GmbH
              </b>
              <br />
              Geschäftsführer: Stephan Winkelmann, Jeremy Kirsten
              <br />
              Nordostpark 45
              <br />
              90411 Nürnberg
              <br />
              <br />
              <FormattedMessage id={`${translationPrefix}.02-decision`} />
              <br />
            </p>
            <h3>
              <FormattedMessage id={`${translationPrefix}.03-tools`} />
            </h3>
            <p>
              <FormattedMessage id={`${translationPrefix}.03-tools-text`} />
            </p>
            <h3>
              <FormattedMessage id={`${translationPrefix}.04-ssl`} />
            </h3>
            <p>
              <FormattedMessage id={`${translationPrefix}.04-ssl-text`} />
            </p>
            <h3>
              <FormattedMessage id={`${translationPrefix}.041-data-processing`} />
            </h3>
            <p>
              <FormattedMessage id={`${translationPrefix}.041-data-processing-text`} />
            </p>
            <h3>
              <FormattedMessage id={`${translationPrefix}.05-subject`} />
            </h3>
            <p>
              <FormattedMessage id={`${translationPrefix}.05-subject-text`} />
            </p>
            <ul>
              <li>
                <FormattedMessage id={`${translationPrefix}.05-li1`} />
              </li>
              <li>
                <FormattedMessage id={`${translationPrefix}.05-li2`} />
              </li>
              <li>
                <FormattedMessage id={`${translationPrefix}.05-li3`} />
              </li>
              <li>
                <FormattedMessage id={`${translationPrefix}.05-li4`} />
              </li>
              <li>
                <FormattedMessage id={`${translationPrefix}.05-li5`} />
              </li>
              <li>
                <FormattedMessage id={`${translationPrefix}.05-li6`} />
              </li>
            </ul>
            <p>
              <FormattedMessage id={`${translationPrefix}.06-consent`} />
              <br />
              <FormattedMessage id={`${translationPrefix}.06-consent-2`} />
              <br />

              <FormattedMessage id={`${translationPrefix}.06-consent-3`} />
              <br />
              <a href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html" target="_blank" rel='noreferrer'>https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.
            </p>
            <h3>
              <FormattedMessage id={`${translationPrefix}.07-officer`} />
            </h3>
            <p>
              <FormattedMessage id={`${translationPrefix}.07-officer-text`} />
              <br />
              <br />
              Email: dsb@mindline-analytics.de
              <br />
              <br />
              <FormattedMessage id={`${translationPrefix}.07-officer-post`} />
              <br />
              <br />
              <b>
                mindline analytics GmbH
              </b>
              <br />
              - Datenschutzbeauftragter -
              <br />
              Bramfelder Straße 115
              <br />
              22305 Hamburg
            </p>
            <h3>
              <FormattedMessage id={`${translationPrefix}.08-changes`} />
            </h3>
            <p>
              <FormattedMessage id={`${translationPrefix}.08-changes-text`} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

GeneralDataProtectionRegulation.propTypes = {};

export { GeneralDataProtectionRegulation };
