import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HashLink } from 'react-router-hash-link';
import logo from '../assets/images/smoove.png';

// import './Imprint.scss';

export const Imprint = () => (
  <div className='page-container container' id='imprint'>
    <div className="app-section">
      <h1 className='text-uppercase text-smv'>
        <FormattedMessage id={'smoove.page.imprint.imprint'} defaultMessage={`Imprint`} />
      </h1>
      <div className='row'>
        <div className='col-md-9'>
          <div className='row mt-4'>
            <div className='col-4'>
              <p>
                <FormattedMessage
                  id={'smoove.page.imprint.responsible-for-content'}
                  defaultMessage={`Responsible for content: `}
                />
              </p>
            </div>
            <div className='col-8'>
              <p>
                <b>mindline analytics GmbH</b>
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <p>
                <FormattedMessage id={'smoove.page.imprint.manager'} defaultMessage={`Managing director: `} />
              </p>
            </div>
            <div className='col-8'>
              <p>Stephan Winkelmann, Jeremy Kirsten</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <p>
                <FormattedMessage id={'smoove.page.imprint.address'} defaultMessage={`Address`} />
              </p>
            </div>
            <div className='col-8'>
              <p>
                Nordostpark 45
                <br />
                D-90411 Nürnberg
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <p>
                <FormattedMessage id={'smoove.page.imprint.contact'} defaultMessage={`Contact: `} />
              </p>
            </div>
            <div className='col-8'>
              <p>
                Fon: +49 (0)911 - 95 64 98 - 0
                <br />
                Fax: +49 (0)911 - 95 64 98 - 29
                <br />
                Web: <a href='https://mindline.de/mindline-analytics/' target="_blank" rel='noreferrer'>www.mindline-analytics.de</a>
              </p>
              <div className='smv-imprint__social'>
                <a href='https://www.linkedin.com/company/mindline-analytics/about/' target='_blank' rel='noreferrer'>
                  <i className='fab fa-linkedin'></i>
                </a>
                <a href='https://www.xing.com/pages/mindlineanalyticsgmbh' target='_blank' rel='noreferrer'>
                  <i className='fab fa-xing-square'></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-3 mt-4'>
          <div className='d-flex justify-content-center flex-column align-items-center'>
            <img src={logo} height={`32px`} alt='mindline analytics' />
            <div>
              {/* {`v${global.REACT_APP_VERSION}`} */}
              <small className='text-center d-flex mt-2'>
                <FormattedMessage
                  id={'smoove.page.imprint.product-of-mla'}
                  defaultMessage={`smoove is a product of mindline analytics GmbH`}
                />
              </small>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-5 smv-imprint__text'>
        <div className='col-md-12'>
          <h4 className='mb-4'>
            <FormattedMessage id={'smoove.page.imprint.copyright-and-tc'} defaultMessage={`Copyright & T&C`} />
          </h4>
        </div>
        <div className='col-md-6'>
          <p>
            <strong>
              <FormattedMessage id={'smoove.page.imprint.copyright'} defaultMessage={`Copyright`} />
            </strong>
            <FormattedMessage
              id={'smoove.page.imprint.copyright-text'}
              defaultMessage={`Text, images, graphics, sound, animations and videos as well as their arrangement on the mindline website are protected by copyright and other protective laws. The content of this website may not be copied, distributed, modified or made available to third parties for commercial purposes. We would like to point out that some of the images contained on the websites are subject to the copyright of third parties.`}
            />
          </p>
          <p>
            <strong>
              <FormattedMessage id={'smoove.page.imprint.liability'} defaultMessage={`Liability`} />
            </strong>
            <FormattedMessage
              id={'smoove.page.imprint.liability-text'}
              defaultMessage={`This website was compiled with the greatest possible care. Nevertheless, no guarantee can be given for the correctness and accuracy of the information contained. Any liability for damages arising directly or indirectly from the use of this website is excluded, unless caused by intent or gross negligence.`}
            />{' '}
            &nbsp;
          </p>
        </div>
        <div className='col-md-6'>
          <p>
            <strong>
              <FormattedMessage id={'smoove.page.imprint.tc'} defaultMessage={`T&C`} />{' '}
            </strong>
            <FormattedMessage id={'smoove.page.imprint.tc-text'} defaultMessage={`T&C - Text`} />
          </p>
          <p>
            <strong>
              <FormattedMessage id={'smoove.page.imprint.external-links'} defaultMessage={`External Links`} />
            </strong>
            <FormattedMessage
              id={'smoove.page.imprint.external-links-text'}
              defaultMessage={`Text, images, graphics, sound, animations and videos as well as their arrangement on the mindline website are protected by copyright and other protective laws. The content of this website may not be copied, distributed, modified or made available to third parties for commercial purposes. We would like to point out that some of the images contained on the websites are subject to the copyright of third parties.`}
            />
          </p>
          <p>
            <strong>
              <FormattedMessage id={'smoove.page.imprint.privacy'} defaultMessage={`Privacy`} />{' '}
            </strong>
            <FormattedMessage
              id={'smoove.page.imprint.privacy-text'}
              defaultMessage={`We take the protection of your personal data very seriously and adhere strictly to the rules of data protection laws. Personal data is only collected on this website to the extent technically necessary. More information can be found on our `}
            />
            <HashLink to='/dataprotection/#'>
              <FormattedMessage id={'smoove.page.imprint.privacy-page'} defaultMessage={'Data Privacy Page.'} />
            </HashLink>
          </p>
        </div>
      </div>
    </div>
  </div>
);
